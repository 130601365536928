@use '../../../scss/var' as var;

.shop-header {
  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  img {
    width: 100%;
    margin-top: 10px;
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px dotted #000;
    border-top: 1px dotted #000;
    padding: 10px 0;

    > * {
      text-transform: uppercase;
      text-decoration: none;
      color: #000000;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid #000000;
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}
