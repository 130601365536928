@use './../../../scss/var' as var;

.landing-bio {
  border-top: 1px solid #333;
  border-bottom: solid 1px #ddd;
  background-image: url(../../../assets/images/bio.jpeg);
  background-size: cover;
  background-position: top left;

  > div {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #000000;

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 1.5px;
      margin: 0 auto 30px;
      text-align: center;
    }

    .bio {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 20px;
      width: 53%;

      .text {
        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          line-height: 180%;
        }
      }
    }
  }
}

@media screen and (max-width: var.$screen-md) {
  .landing-bio {
    > div {
      h4 {
        margin-top: 0;
      }
      .bio {
        .text {
          p {
            margin-bottom: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: var.$screen-sm) {
  .landing-bio {
    background-position: -600px center;
    background-repeat: no-repeat;
    > div {
      .bio {
        width: calc(100% - 40px);
      }
    }
  }
}
