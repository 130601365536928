@use '../../../scss/var' as var;

.cart-body {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 1.5px;
    color: #000000;
    margin-bottom: 30px;
    padding: 20px 0;
  }

  h4 {
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 1.5px;
    color: #000000;
    margin-top: 10px;
  }

  .coupon-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    .input {
      width: 80%;
    }
  }

  .btn {
    padding: 8px;
    border: 1px solid #000000;
    text-decoration: none;
    color: #000000;
    border-radius: 7px;
    text-transform: none;
    font-size: 16px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    a {
      padding: 8px;
      border: 1px solid #000000;
      text-decoration: none;
      color: #000000;
      border-radius: 7px;

      &:hover {
        background-color: #cccccc;
      }
    }
  }
}
