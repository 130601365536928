@use '../../../scss/var' as var;

.order-body {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    padding: 20px 0;
  }
}
