@use '../../../scss/var' as var;

.about {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;

    h2 {
      font-size: 34px;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 60px;
    }

    .text {
      font-family: 'ヒラギノ角ゴ Pro', sans-serif;
      font-size: 14px;
      line-height: 1.6em;
      color: #535353;
    }

    .sns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      .fb {
        margin-right: 40px;

        svg {
          color: #1a77f2;
        }
      }

      .twitter {
        margin-right: 40px;
        svg {
          color: #1ea2f1;
        }
      }

      .instagram {
        svg {
          color: #6457c7;
        }
      }

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
}
