.scroll-top {
  position: fixed;
  right: 15px;
  bottom: 15px;

  .btn {
    background-color: #000;
  }

  svg {
    font-size: 3em;
    fill: #ffffff;
  }
}
