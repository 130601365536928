@use '../../../scss/var' as var;

$box-width: 644px;
$box-padding: 40px;
$box-padding-mobile: 20px;

.login-box {
  //min-width: $box-width;
  width: 80%;
  max-width: $box-width;
  max-height: 622px;
  min-height: 290px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
  padding: $box-padding;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 80px;

  form {
    width: 100%;
  }

  .submit-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;

    .submit {
      padding: 8px;
      border: 1px solid #000000;
      text-decoration: none;
      color: #000000;
      border-radius: 7px;
      text-transform: none;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: var.$screen-sm) {
  .login-box {
    padding: $box-padding-mobile;
  }
}
