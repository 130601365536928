@use '../../../scss/var' as var;

.shop-entry {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;

    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      margin-left: 50px;
    }

    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        width: 50%;
        min-width: 50%;
      }

      .text-container {
        width: 100%;
        text-align: right;
        font-size: 18px;

        .description {
          width: 100%;
          text-align: left;
          margin-bottom: 30px;
        }

        .price {
          width: 100%;
          text-align: right;
          font-weight: bold;
          font-size: 40px;
          padding: 10px;
          border-top: 1px dashed #000000;
          border-bottom: 1px dashed #000000;
        }

        .add-to-card {
          margin-top: 30px;
          max-width: 100px;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 30px;

      a {
        padding: 8px;
        border: 1px solid #000000;
        text-decoration: none;
        text-transform: none;
        font-size: 16px;
        color: #000000;
        border-radius: 7px;
      }
    }
  }
}

@media screen and (max-width: var.$screen-md) {
  .shop-entry {
    > div {
      .container {
        flex-direction: column;

        img {
          width: 100%;
          min-width: 100%;
          min-height: 340px;
        }

        .text-container {
          text-align: center;
        }
      }
    }
  }
}
