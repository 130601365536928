@use '../../../scss/var' as var;

.policy {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;

    h2 {
      font-size: 34px;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 60px;
    }

    .text {
      font-family: 'ヒラギノ角ゴ Pro', sans-serif;
      font-size: 14px;
      line-height: 1.6em;
      color: #535353;

      dt {
        font-weight: bold;
        margin: 15px 0 8px;
      }

      img {
        max-height: 16px;
      }
    }
  }
}
