.stripe-container {
  margin: 10px;
  padding: 10px;
  border: 2px solid #000000;
  border-radius: 7px;
}

.stripe-btn-container {
  width: 100%;
  text-align: right;
  margin: 30px 0;

  .btn {
    padding: 8px;
    border: 1px solid #000000;
    text-decoration: none;
    text-transform: none;
    font-size: 16px;
    color: #000000;
    border-radius: 7px;
  }
}
