@use './../../../scss/var' as var;

.landing-disco {
  width: 100%;
  min-height: 350px;
  background-color: #ffffff;

  > div {
    max-width: 1200px;
    margin: 0 auto;
    color: #000000;

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 1.5px;
      margin: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;
    }

    .disco {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      div {
        width: 300px;
        margin: 0 30px 40px;
        cursor: pointer;
      }
      img {
        width: auto;
        height: 300px;
        border: solid 1px #ddd;
      }

      h5 {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}

.backdrop-yellow {
  background-color: rgba(255, 255, 0, 0.9) !important;
}

.landing-disco-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 80%;
  color: #000000;

  > div {
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #000000;

    .img {
      width: 500px;
      text-align: center;

      img {
        width: auto;
        height: auto;
        max-width: 500px;
        max-height: 500px;
      }
    }
    .text {
      padding: 20px 20px 20px 50px;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: var.$screen-sm) {
  .landing-disco-modal {
    height: 90%;
    > div {
      flex-direction: column;
      .img {
        img {
          max-width: 250px;
          max-height: 250px;
        }
      }

      .text {
        padding: 10px 10px 10px 30px;

        p {
          margin: 0;
        }
      }
    }
  }
}
