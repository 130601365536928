@use '../../scss/var' as var;

.entry {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  min-height: 500px;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    margin-top: 100px;

    h2 {
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 30px;
      margin-left: 50px;
    }

    .container {
      background-color: #ffffff;
      color: #000000;
      border-radius: 5px;
      padding: 60px;

      h3 {
        font-size: 30px;
        font-weight: normal;
        letter-spacing: 1.5px;
        margin-bottom: 30px;
      }

      .date {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 10px 5px;
        background-color: rgba(0, 143, 255, 0.9);
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-style: italic;
        line-height: 200%;

        > * {
          margin-left: 15px;
        }
      }

      .sns {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content {
        margin-top: 50px;

        .main-img {
          width: 80%;
          margin: 0 10%;
        }
      }

      .list-container {
        border-top: 1px solid black;
        margin-top: 60px;

        h4 {
          text-transform: uppercase;
        }

        .row {
          margin-top: 6px;
        }
      }
    }
  }
}

@media screen and (max-width: var.$screen-md) {
  .entry {
    > div {
      .container {
        padding: 20px;
        .content {
          .main-img {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}
