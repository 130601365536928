@use './login-form-builder/login-form-builder.module.scss';
@use './login-box/login-box.module.scss' as lbox;
@use './oauth2-buttons/oauth2-buttons.module.scss';
@use '../../scss/var' as var;

.login {
  width: 100%;
  min-height: 100vh;
  background-image: url('./../../assets/images/bg.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  .title {
    width: 80%;
    max-width: lbox.$box-width;
    font-size: 40px;
    color: #ffffff;
    margin-top: 200px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: var.$screen-sm) {
  .login {
    .title {
      font-size: 70px;
    }
  }
}
