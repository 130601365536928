@use '../../../scss/var' as var;

.ty-body {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    height: calc(100vh - 80px);
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      max-width: 500px;
      position: absolute;
    }

    h1 {
      margin-top: 300px;
      color: black;
      z-index: 5;
    }

    .btn {
      padding: 8px;
      border: 1px solid #000000;
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      color: #000000;
      border-radius: 7px;
      z-index: 5;
    }
  }
}
