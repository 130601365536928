.cart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  background: #ffffff;
  color: #000000;
  border-radius: 7px;
  border: 2px solid #000000;

  .table {
    .row {
      background-color: #ffffff;

      &:nth-of-type(odd) {
        background-color: #cccccc;
      }

      .btn {
        background-color: #000;

        svg {
          //font-size: 3em;
          fill: #ffffff;
        }
      }
    }
  }
  .total {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    .total-box {
      padding: 10px;
      border-left: 2px solid #000000;
      border-top: 2px solid #000000;
      border-top-left-radius: 7px;

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        .text {
          margin-right: 100px;
          font-weight: bold;
          text-decoration: underline;
        }

        .value {
          text-align: right;
        }
      }
    }
  }
}
