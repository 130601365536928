@use './../../scss/var' as var;

header {
  width: 100%;
  height: var.$header-height;
  background-color: #ffffff;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    .buttons {
      > *:not(:last-child) {
        margin-right: 20px;
      }

      .icon {
        margin: 0;
      }

      .button {
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        color: #333;
      }
    }

    .icon-size {
      font-size: 13px !important;
    }

    .name {
      font-size: 20px;
      color: #000000;
      text-decoration: none;
      line-height: 140%;
      letter-spacing: 1.5px;
      font-weight: normal;
      margin-right: 30px;
    }
  }
}

.menu-list {
  background-color: rgba(0, 143, 255, 0.9);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;

  .close-item {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .item {
    .item-text {
      font-size: 30px !important;
      text-transform: uppercase;
      text-decoration: none;
      color: #ffffff;
      text-align: center;
    }

    &.hidden {
      display: none;
    }
  }
}

@media screen and (max-width: var.$screen-sm) {
  header {
    > div {
      .name {
        margin-right: 0;
      }

      .buttons {
        > *:not(:last-child) {
          margin-right: 0;
        }
        .button {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: var.$screen-xs) {
  header {
    > div {
      .name {
        font-size: 16px;
      }

      .icon-size {
        font-size: 11px !important;
      }
    }
  }
}
