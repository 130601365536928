@use '../../../scss/var' as var;

.login-form-builder {
  width: 100%;

  .label {
    font-size: 17px;
  }

  .error {
    color: #ff0000;
  }
}
