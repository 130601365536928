@use '../../../scss/var' as var;

.checkout-body {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  h3 {
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    padding: 20px 0;
  }

  .bill-address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 7px;
    border: 2px solid #000000;
    padding: 10px;

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex: 50%;
      > * {
        width: 100%;
        flex: 50%;
      }

      .required {
        color: #ff0000;
      }
    }
  }

  .btn-container {
    width: 100%;
    margin: 30px 0;
    text-align: right;

    .btn {
      padding: 8px;
      border: 1px solid #000000;
      text-decoration: none;
      color: #000000;
      border-radius: 7px;
      text-transform: none;
      font-size: 16px;
    }
  }
}
