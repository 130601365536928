@use '../../scss/var' as var;

.page-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/logohd.jpeg');
  background-size: cover;
  background-position: center;
  color: #000000;
  text-transform: uppercase;

  .img {
    width: 159px;
    min-width: 159px;
    height: 40px;
    font-size: 12px;
    margin-bottom: 20px;
  }
}
