.shop-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 500px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #4d4d4d;
    margin: 50px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      max-height: 206px;
      max-width: 206px;
      border: 1px dashed transparent;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-top: 5px;
      color: #4d4d4d;
      text-decoration: none;
    }

    .price {
      font-size: 11px;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 5px;
    }
  }
}
