@use '../../../scss/var' as var;

.contact {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;

    h2 {
      font-size: 34px;
      text-transform: uppercase;
      letter-spacing: 3px;
    }

    .text {
      color: #000;
      font-size: 13px;
    }

    h4 {
      span {
        color: #ff0000;
      }
    }

    .file-upload {
      display: none;
    }

    .btn-container {
      width: 100%;
      margin: 30px 0;
      text-align: center;

      .btn {
        padding: 8px;
        border: 1px solid #000000;
        text-decoration: none;
        color: #000000;
        border-radius: 7px;
        text-transform: none;
        font-size: 16px;
      }
    }
  }
}
