html {
  height: 100%;
}

* {
  line-height: 140%;
}

body {
  background: #000000;
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: '游ゴシック', 'Yu Gothic', YuGothic, 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'メイリオ',
    Meiryo, 'ＭＳ ゴシック', sans-serif;
}
