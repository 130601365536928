.landing-video {
  width: 100%;
  min-height: 350px;
  background-color: #000;
  color: #ffffff;

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .carousel-root {
      width: 100%;
    }

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 1.5px;
      margin: 0 auto 30px;
      text-align: center;
    }
    iframe {
      max-width: 800px;
      max-height: 450px;
    }
  }
}
