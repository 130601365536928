footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 10px 0;
  font-weight: bold;
  background-color: #000000;
  color: #999999;

  a {
    color: #ffffff;
    margin-left: 10px;
  }
}
