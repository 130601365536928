@use '../../../scss/var' as var;

.oauth2-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .oauth2-button {
    height: 30px;
    width: 120px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #535353;
    display: flex;
    align-items: center;
    margin-left: 5px;

    .icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-left: 5px;
    }

    .label {
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: var.$screen-sm) {
  .oauth2-container {
    flex-direction: column;
    .button-list {
      .oauth2-button {
        margin-top: 5px;
      }
    }
  }
}
