@use './../../../scss/var' as var;

.landing-news {
  width: 100%;
  min-height: 350px;
  background-image: url('../../../assets/images/news.jpeg');
  background-position: center;
  background-size: cover;
  background-color: #eee;

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 1.5px;
      color: #333333;
      margin-bottom: 30px;
      text-align: center;
    }

    .row {
      display: flex;
      align-items: center;
      color: #333333;
      margin-bottom: 20px;
      margin-left: 5%;
      margin-right: 5%;

      .date {
        position: relative;
        font-size: 13px;
        font-weight: bold;

        .new {
          position: absolute;
          top: -3px;
          left: -60px;
          color: #ffffff;
          background-color: #ff0000;
          text-transform: uppercase;
          border-radius: 0;
        }
      }

      .link {
        margin-left: 60px;
        color: #333333;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: var.$screen-md) {
  .landing-news {
    > div {
      padding: 20px 10px;

      h3 {
        margin-bottom: 15px;
      }

      .row {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;

        .date {
          font-size: 10px;
          margin-bottom: 3px;

          .new {
            top: -4px;
            left: initial;
            right: -60px;
          }
        }

        .link {
          margin: 7px 0;
        }
      }

      .mobile-backdrop {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;

        h3,
        .link,
        .row {
          color: #ffffff !important;
        }
      }
    }
  }
}

@media screen and (max-width: var.$screen-sm) {
  .landing-news {
    min-height: 200px;
    background-position: center right;
  }
}
