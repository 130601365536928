@use '../../scss/var' as var;

.list-entry {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  min-height: 500px;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    margin-top: 100px;

    h2 {
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 30px;
      margin-left: 50px;
    }

    .container {
      background-color: #000000;
      border-radius: 5px;
      border: 2px solid #ffffff;
      padding: 10px;

      .row {
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 20px;
        margin-left: 5%;
        margin-right: 5%;

        .date {
          position: relative;
          font-size: 13px;
          font-weight: bold;

          .new {
            position: absolute;
            top: -3px;
            left: -60px;
            color: #ffffff;
            background-color: #ff0000;
            text-transform: uppercase;
            margin-right: 5px;
            border-radius: 0;
          }
        }

        .link {
          margin-left: 60px;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: var.$screen-md) {
  .list-entry {
    > div {
      .container {
        .row {
          flex-direction: column;
          align-items: flex-start;
          .date {
            .new {
              top: -3px;
              left: initial;
              right: -60px;
            }
          }
          .link {
            margin: 7px 0;
          }
        }
      }
    }
  }
}
