/* Header */
$header-height: 50px;

/* Screen Breakpoint */

$screen-xs: 400px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1920px;
