@use '../../../scss/var' as var;

.account {
  width: 100%;
  min-height: 100vh;
  background-image: url('./../../../assets/images/bg.jpeg');
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  font-family: 'ヒラギノ角ゴ Pro', sans-serif;

  &.fixed-header {
    margin-top: var.$header-height;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;

    h2 {
      font-size: 34px;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 60px;
    }

    .container {
      width: 100%;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 7px;
      color: #000000;

      h4 {
        text-transform: uppercase;
      }

      .password-container {
        .password-input {
          margin-bottom: 30px;
        }
      }

      .email-info {
        font-size: 12px;
      }
    }
  }

  .btn {
    padding: 8px;
    border: 1px solid #000000;
    text-decoration: none;
    color: #000000;
    border-radius: 7px;
    text-transform: none;
    font-size: 16px;
  }

  .orders {
    background-color: transparent;
    padding-bottom: 60px;
  }
}
