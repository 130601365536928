.bg-fixed {
  background-image: url(../../assets/images/bg.jpeg?ver5);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: -1;
}
