.landing-mail {
  width: 100%;
  background-image: url(../../assets/images/bg-noise.gif);
  background-repeat: repeat;
  text-align: center;
  color: #ffffff;

  > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h3 {
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 1.5px;
      margin: 0 auto 20px;
      text-align: center;
    }

    .text {
      width: 100%;
      height: 100%;

      .input {
        input {
          width: 30%;
          height: 20px;
          padding: 5px 10px;
          font-size: 13px;
          border: none;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          display: inline-block;
          vertical-align: top;

          &:focus {
            outline: 0;
          }
        }

        button {
          width: 100px;
          height: 30px;
          text-align: center;
          padding: 5px;
          background-color: #24a7d5;
          color: #fff;
          font-size: 13px;
          border: none;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          display: inline-block;
          vertical-align: top;
        }
      }
      .next {
        margin-top: 30px;

        a {
          color: rgba(255, 255, 255, 0.8);
          text-decoration: none;

          &:not(:last-child) {
            margin-right: 20px;
          }

          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}
